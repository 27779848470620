import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги | Silver Shears
			</title>
			<meta name={"description"} content={"Винятковий догляд, кожного разу"} />
			<meta property={"og:title"} content={"Наші послуги | Silver Shears"} />
			<meta property={"og:description"} content={"Винятковий догляд, кожного разу"} />
			<meta property={"og:image"} content={"https://gloritoni.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://gloritoni.com/img/5029057.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://gloritoni.com/img/5029057.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://gloritoni.com/img/5029057.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://gloritoni.com/img/5029057.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://gloritoni.com/img/5029057.png"} />
			<meta name={"msapplication-TileImage"} content={"https://gloritoni.com/img/5029057.png"} />
			<meta name={"msapplication-TileColor"} content={"https://gloritoni.com/img/5029057.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Наші послуги
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
			У перукарні Silver Shears ми прагнемо надавати повний спектр послуг з догляду за собою, щоб задовольнити різноманітні потреби наших клієнтів. Наші досвідчені барбери використовують свої знання та навички для надання першокласних послуг, які допоможуть вам виглядати та почуватися якнайкраще. Ось детальний огляд того, що ми пропонуємо:

			</Text>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Стрижки
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Класичні стрижки: Позачасові фасони, які ніколи не виходять з моди, ретельно виконані до досконалості.
					<br />
					<br />
   Стрижка "під каре": Чиста, коротка стрижка, яку легко доглядати.
					<br />
					<br />
   Бокова частина: Універсальний стиль, який підходить як для повсякденних, так і для офіційних подій.
					<br />
					<br />
Сучасні фасони: Модні фасони, що відображають останні тенденції в чоловічій моді.

				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://gloritoni.com/img/6.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://gloritoni.com/img/7.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Гоління та догляд за бородою
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Традиційне гоління: Розкішний досвід з гарячими рушниками та преміальними засобами для гоління.
					<br />
					<br />
   Гоління гарячим рушником: Розслаблююче гоління теплими рушниками для пом'якшення шкіри.
					<br />
					<br />
   Гоління прямою бритвою: Ретельне, точне гоління для гладенького результату.
					<br />
					<br />
Підстригання бороди: індивідуальне підстригання для надання форми та догляду за бородою.
					<br />
					<br />
   Повна стрижка бороди: Детальна стрижка для надання форми та окреслення вашої бороди.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Стайлінг
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Перукарські послуги: Персоналізована укладка для будь-якого випадку.
					<br />
					<br />
   Помпадур: Класичний стиль з висотою та об'ємом.
					<br />
					<br />
   Чубчик: Сучасний, фактурний стиль з виразним зовнішнім виглядом.
					<br />
					<br />
Event Styling: Спеціальні стилі для важливих подій.
					<br />
					<br />
   Весільний стайлінг: Елегантні та відшліфовані образи для вашого великого дня.
					<br />
					<br />
   Формальний стиль для урочистих подій: Елегантні зачіски для урочистих подій.

				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://gloritoni.com/img/8.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});